import React from 'react'
import Carousel from 'react-slick'
import classNames from 'classnames'

import { withLazyRef } from '@util/lazy_ref'

import styles from '@components/blocks/quote_block.module.scss'

const Quote = ({ quote, ...props }) => {
  const quoteFields = quote.fields
  return (
    <div className="slide" {...props}>
      <div className={styles.quote}>
        <div className={styles.quote__content}>
          <div className={classNames(styles.quote__quote, 'quote__quote')}>{quoteFields.quote}</div>
          <div className={classNames(styles.quote__author, 'quote__author')}>
            {quoteFields.author}
          </div>
          <div className={classNames(styles.quote__authorDescription, 'quote__authorDescription')}>
            {quoteFields.authorDescription}
          </div>
        </div>
      </div>
    </div>
  )
}

class QuoteBlock extends React.Component {
  getPageBackgroundColor = () => {
    return 'bluePattern'
  }

  render() {
    const fields = this.props.fields
    const quotes = fields.quotes.map((quote, i) => <Quote key={i} quote={quote} />)
    const carouselSettings = {
      dots: quotes.length > 1,
      adaptiveHeight: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 10000,
      speed: 500,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
    }

    const useCarousel = quotes.length > 1

    return (
      <div className={styles.quoteBlock}>
        <div className={styles.quoteBlock__content}>
          {useCarousel && <Carousel {...carouselSettings}>{quotes}</Carousel>}
          {!useCarousel && <div>{quotes[0]}</div>}
        </div>
      </div>
    )
  }
}

export default withLazyRef(QuoteBlock)
